<template>
	<div class="analytics-page mx-2">
		<div v-if="!isFullScreenTable" class="d-flex mb-5 mt-3">
			<template v-for="sort in sortsBtn">
				<v-btn
					outlined
					:color="activeSort === sort.type  ? 'primary' : 'light'"
					rounded
					class="text-none mr-5 btn-sort"
					:class="{'bg-lightgreen': activeSort === sort.type }"
					@click="setFilter(sort.type)"
				>
          <img v-if="sort.icon === 'mdi-home-outline' && activeSort !== sort.type" src="@/assets/home-outline.svg" class="mr-2" width="20">
          <img v-else-if="sort.icon === 'mdi-home-outline' && activeSort === sort.type" src="@/assets/home-outline-primary.svg" class="mr-2" width="20">
					<v-icon v-else left>
						{{sort.icon}}
					</v-icon>
					{{ sort.name }}
				</v-btn>
			</template>
		</div>
		<!-- ФИЛЬТРЫ -->
    <div v-if="!isFullScreenTable">
      <div class="analytics-filter-block mb-5">
        <v-row>
          <v-col sm="2" md="2" lg="3" xl="4">
            <p class="customDark--text mb-1">Адрес дома</p>
            <combobox
              v-model="filter.houses_ids"
              placeholder="Адрес"
              :items="houses"
              @change="updateQuery"
              @select-all="updateFilter('houses_ids', $event)"
            />
          </v-col>
          <v-col sm="2" lg="2">
            <p class="customDark--text mb-1">Период</p>
            <DatePickerPlain
              :date.sync="filter.period"
              :range="true"
              @change="updateQuery"
            />
          </v-col>
          <v-col sm="2" lg="2">
            <p class="customDark--text mb-1">Категория заявки</p>
            <combobox
              v-model="filter.category_ids"
              placeholder="Категория"
              itemText="name"
              :items="catalog.request_categories"
              @change="updateQuery"
              @select-all="updateFilter('category_ids', $event)"
            />
          </v-col>
          <v-col sm="5" md="6" lg="5" xl="4" class="d-flex justify-center">
            <div class="mr-5">
              <p class="customDark--text mb-1">Видимость заявки</p>
              <div id="tabs-block" class="d-flex tabs-block">
                <div class="custom-tabs d-flex align-center">
                  <div
                    :class="['tab', 'custom-tabs-' + visibleTab, { 'tab-active': visibleTab === 'individ', 'tab-1': !visibleTab }]"
                    class="px-4 py-1"
                    @click="visibleTab === 'individ' ? visibleTab = '' : visibleTab = 'individ'; updateQuery(false)"
                  >
                    Индивидуал
                  </div>
                  <div
                    :class="['tab', 'custom-tabs-' + visibleTab, { 'tab-active': visibleTab === 'public' }]"
                    class="px-4 py-1"
                    @click="visibleTab === 'public' ? visibleTab = '' : visibleTab = 'public'; requestTypeTab = ''; updateQuery(false)"
                  >
                    Публичная
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p class="customDark--text mb-1">Вид заявки</p>
              <div id="tabs-block" class="d-flex tabs-block">
                <div class="custom-tabs d-flex align-center" :class="{'disable-item': visibleTab === 'public'}">
                  <div
                    :class="['tab', 'custom-tabs-' + requestTypeTab, { 'tab-active': requestTypeTab === 'inner', 'tab-1': !requestTypeTab }]"
                    class="px-4 py-1"
                    @click="requestTypeTab === 'inner' ? requestTypeTab = '' : requestTypeTab = 'inner'; visibleTab = 'individ'; updateQuery(false)"
                  >
                    Внутренняя
                  </div>
                  <div
                    :class="['tab', 'custom-tabs-' + requestTypeTab, { 'tab-active': requestTypeTab === 'tenants' }]"
                    class="px-4 py-1"
                    @click="requestTypeTab === 'tenants' ? requestTypeTab = '' : requestTypeTab = 'tenants'; visibleTab = 'individ'; updateQuery(false)"
                  >
                    От жителей
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- По помещениям -->
      <div v-if="activeSort === 'by-rooms'" class="d-flex">
        <div class="analytics-filter-block mb-5 mr-4">
          <div class="d-flex">
            <div class="mx-2">
              <p class="customDark--text mb-1">Тип помещения</p>
              <v-select
                v-model="filter.room_types"
                class="border-all"
                placeholder="Все"
                solo
                dense
                hide-details
                flat
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :items="catalog.room_types"
                @change="updateQuery"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- Все заявки -->
      <div v-if="activeSort === 'all'" class="d-flex">
        <div class="analytics-filter-block mb-5 mr-4">
          <div class="d-flex">
            <div class="mx-2">
              <p class="customDark--text mb-1">Номер заявки</p>
              <v-text-field
                v-model="filter.pretty_id"
                class="border-all"
                placeholder="Все (введите номер)"
                solo
                dense
                flat
                hide-details
                @change="updateQuery"
              >
              </v-text-field>
            </div>
            <div class="mx-2">
              <p class="customDark--text mb-1">Тип помещения</p>
              <v-select
                v-model="filter.room_types"
                class="border-all"
                placeholder="Все"
                solo
                dense
                hide-details
                flat
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="id"
                :items="catalog.room_types"
                @change="updateQuery"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="analytics-filter-block mb-5 w-100">
          <div class="d-flex">
            <div class="mx-2">
              <p class="customDark--text mb-1">Собственник</p>
              <v-text-field
                v-model="filter.user_phone"
                class="border-all"
                placeholder="Все (введите номер)"
                v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
                solo
                dense
                flat
                hide-details
                @change="updateQuery"
              >
              </v-text-field>
            </div>
            <div class="mx-2">
              <p class="customDark--text mb-1">Статус заявки</p>
              <v-select
                v-model="filter.requests_types"
                class="border-all"
                placeholder="Все"
                solo
                dense
                hide-details
                flat
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="id"
                :items="catalog.request_types"
                @change="updateQuery"
              ></v-select>
            </div>
            <div class="mx-2">
              <p class="customDark--text mb-1">Исполнитель</p>
              <v-select
                v-model="filter.employee_worker_executor_ids"
                class="border-all"
                placeholder="Все"
                solo
                dense
                hide-details
                flat
                append-icon="mdi-chevron-down"
                item-value="id"
                :item-text="item => getFullname(item)"
                :items="employeeList"
                @change="updateQuery"
              ></v-select>
            </div>
            <div class="mx-2">
              <p class="customDark--text mb-1">Кто назначил</p>
              <v-select
                v-model="filter.user_created_ids"
                class="border-all"
                placeholder="Все"
                solo
                dense
                hide-details
                flat
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="id"
                :item-text="item => getFullname(item)"
                :items="managersList"
                @change="updateQuery"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- По исполнителям -->
      <div v-else-if="activeSort === 'by-executors' || activeSort === 'by-managers'" class="d-flex">
        <div class="analytics-filter-block mb-5 mr-4">
          <div class="d-flex">
            <div class="mx-2">
              <p class="customDark--text mb-1">Должность в роли исполнителя</p>
              <v-select
                v-model="filter.manager_position"
                class="border-all"
                placeholder="Менеджер"
                solo
                dense
                flat
                hide-details
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="id"
                :items="catalog.employee_positions"
                @change="updateQuery"
              >
              </v-select>
            </div>
            <div class="mx-2">
              <p class="customDark--text mb-1">ФИО</p>
              <v-select
                v-model="filter.employee_workers_ids"
                class="border-all"
                placeholder="Все"
                solo
                dense
                hide-details
                flat
                append-icon="mdi-chevron-down"
                item-value="id"
                :item-text="item => getFullname(item)"
                :items="employee"
                @change="updateQuery"
              ></v-select>
            </div>
            <div v-if="activeSort === 'by-executors'" class="mx-2">
              <v-checkbox
                v-model="hideAddress"
                label="Показать без адреса домов"
                class="mt-7 hide-address"
                hide-details
                @change="hideAddresses">

              </v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-block mb-2"> 
      <div class="d-flex align-center justify-space-between">
        <v-select
          v-if="base_role === 'administrator'"
          v-model="filter.company_ids"
          class="border-all"
          placeholder="Выберите организацию (УК/ОСИ/СК) "
          solo
          dense
          hide-details
          flat
          append-icon="mdi-chevron-down"
          item-text="name"
          item-value="_id"
          :items="kskListConfirm"
          @change="updateQuery"
          style="max-width: 300px"
        ></v-select>
        <span v-else></span>
        <div class="d-flex align-center">
          <v-btn
            class="mr-5 text-lowercase"
            color="primary"
            small
            outlined
            @click="resetFilters"
          >
            <span class="body-2 font-weight-light">
              <span class="text-capitalize">Сбросить</span> фильтры
            </span>
          </v-btn>
          <v-btn
            class="mr-5"
            color="primary"
            small
            depressed
            :loading="isExportingExcel"
            @click="exportExcel"
            v-if="base_role !== 'dispatcher'"
          >
            <v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
            <span class="body-2 font-weight-light text-capitalize">
              Экспорт
            </span>
          </v-btn>
          <v-btn
            @click="isFullScreenTable = !isFullScreenTable"
            class="pa-0"
            small
            depressed
            color="white"
            elevation="0"
            style="min-width: 30px"
          >
            <img v-if="!isFullScreenTable" src="@/assets/expand-icon.svg">
            <img v-else src="@/assets/decrease-icon.svg">
          </v-btn>
        </div>
      </div>
    </div>
    <div class="analytics-container">
      <v-data-table
        class="overflow-x elevation-1 infinite-list analytics-table"
        :headers="headers"
        :items="computedItems"
        :loading="loading"
        :page.sync="options.page"
        :options="options"
        :server-items-length="options.itemsLength"
        height="100%"
        item-key="id"
        hide-default-footer
      >
        <!-- По исполнителям с адресами -->
        <template v-if="(activeSort === 'by-executors' || activeSort === 'by-managers') && !hideAddress && !loading" #body="{ items }">
          <tbody>
            <template v-for="(item, itemIndex) in items">
              <template v-if="item.houses && item.houses.length > 0">
                <tr :class="getRowClass(itemIndex)">
                  <td :rowspan="item.houses.length">{{ itemIndex + 1 }}</td>
                  <td :rowspan="item.houses.length">{{ item.name }}, <br>{{ item.phone }}</td>
                  <td v-if="activeSort === 'by-executors'" :rowspan="item.houses.length">{{ item.positions }}</td>
                  <template v-for="(field, fieldIndex) in getHouseFields(item.houses[0])">
                    <td>{{ field }}</td>
                  </template>
                </tr>
                <tr v-for="(house, houseIndex) in item.houses.slice(1)" :class="getRowClass(itemIndex)">
                  <template v-for="(field, fieldIndex) in getHouseFields(house)">
                    <td>{{ field }}</td>
                  </template>
                </tr>
              </template>

              <!-- Обработка, если дома отсутствуют -->
              <template v-else>
                <tr :class="getRowClass(itemIndex)">
                  <td :rowspan="item.houses.length">{{ itemIndex + 1 }}</td>
                  <td>{{ item.name }}, <br>{{ item.phone }}</td>
                  <td v-if="activeSort === 'by-executors'">{{ item.positions }}</td>
                  <td colspan="12">Нет данных</td>
                </tr>
              </template>
            </template>

            <tr v-if="totals && Object.keys(totals).length > 0 && !loading && activeSort !== 'all'" class="totals-row">
              <td>Итого</td>
              <td v-for="header in headers.slice(1)" :key="header.value">
                {{ getTotalForColumn(header.value) }}
              </td>
            </tr>
          </tbody>
        </template>
        <template v-if="totals && Object.keys(totals).length > 0 && !loading && activeSort !== 'all'" #body.append>
          <tr class="totals-row">
            <td>Итого</td>
            <td v-for="header in headers.slice(1)" :key="header.value">
              {{ getTotalForColumn(header.value) }}
            </td>
          </tr>
        </template>
        <template #item.index="{ index }">
          {{ index + 1 }}
        </template>
        <template #item.created_at="{ item }">
          {{ item.created_at | timestamp(true) }}
        </template>
      </v-data-table>
    </div>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import Combobox from '@/components/app/Combobox.vue';
import addressFilterMixin from "@/mixins/addressFilterMixin";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import NotificationsTableModal from "@/components/NotificationsTableModal";

export default {
	name: "AnalyticsPage",
	mixins: [addressFilterMixin],
	components: {
		Combobox,
		DatePickerPlain,
		NotificationsTableModal,
	},
	data() {
		return {
			items: [],
      totals: {},
			activeSort: 'all',
			visibleTab: '',
			requestTypeTab: '',
      hideAddress: false,
      isFullScreenTable: false,
			sortsBtn: [
				{ name: 'По домам', type: 'by-houses', icon: 'mdi-home-outline' },
				{ name: 'По помещениям', type: 'by-rooms', icon: 'mdi-sofa-outline' },
				{ name: 'По менеджерам', type: 'by-managers', icon: 'mdi-account-outline' },
				{ name: 'По исполнителям', type: 'by-executors', icon: 'mdi-account-multiple-outline' },
				{ name: 'По категориям заявок', type: 'by-categories', icon: 'mdi-list-box-outline' },
				{ name: 'Все заявки', type: 'all', icon: 'mdi-file-document-outline' },
			],
			filter: {
				type_id: '',
				pretty_id: this.$route.query.pretty_id || '',
				requests_types: this.$route.query.requests_types || '',
				manager_position: this.$route.query.manager_position || '5f4cda340796c90b114f557e',
				employee_worker_executor_ids: this.$route.query.employee_worker_executor_ids || '',
				user_created_ids: this.$route.query.user_created_ids || '',
        category_ids: this.$route.query.category_ids || [],
        user_phone: this.$route.query.user_phone || "",
        room_types: this.$route.query.room_types || "",
        company_ids: this.$route.query.company_ids || "",
        houses_ids: this.$route.query.houses_ids || [],
        period: this.$route.query.period || [],
        employee_workers_ids: this.$route.query.employee_workers_ids || "",
			},
			filtersQuery: {
        "houses_ids": "houses_ids",
        "user_phone": "user_phone",
        "period": "period",
        "manager_position": "manager_position",
        "room_types": "room_types[]",
        "company_ids": "company_ids",
        "employee_workers_ids": "employee_workers_ids[]",
        "pretty_id": "pretty_id",
        "category_ids": "category_ids",
        "requests_types": "requests_types[]",
        "employee_worker_executor_ids": "employee_worker_executor_ids[]",
        "user_created_ids": "user_created_ids[]",
			},
			headers: [
				{ text: "Номер заявки", value: "pretty_id", width: "120px", sortable: false },
				{ text: "Дата создания", value: "created_at", width: "150px", sortable: false },
				{ text: "Статус заявки", value: "type", width: "150px", sortable: false },
				{ text: "Адрес дома", value: "house", width: "250px", sortable: false },
				{ text: "Тип помещения", value: "room_type", width: "150px", sortable: false },
				{ text: "№ помещения", value: "apartment_number", width: "120px", sortable: false },
				{ text: "Телефон", value: "user_phone", width: "150px", sortable: false },
				{ text: "Категория", value: "category_name", width: "150px", sortable: false },
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: -1,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			isExportingExcel: false,
			loading: true,
			firstFetch: false,
			showModalCreate: false,
			toEdit: null,
			selectAllUserId: false,
			selectAllHouseId: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
      employee: "ksk/GET_EMPLOYEE",
			base_role: "auth/GET_USER_ROLE",
			role: "auth/GET_USER_ROLE_VUEX",
			kskId: "auth/GET_KSK_ID",
			kskListConfirm: "catalog/GET_KSK_LIST_CONFIRM",
			userHouses: "auth/GET_HOUSES_IDS",
      phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
    employeeList() {
      return this.employee.filter(person => person.role.name_system === "ksk_employee");
    },
    managersList() {
      return this.employee.filter(person => person.role.name_system === "ksk_manager");
    },
    computedItems() {
      switch (this.activeSort) {
        case "by-categories":
        if (!this.items || !Array.isArray(this.items)) {
          return [];
        }

        return this.items.map(item => {
          const row = { apartment_address: item.apartment_address };

          item.categories.forEach(category => {
            if (!row[category.name]) {
              row[category.name] = category.total_requests_count;
            }
          });
          return row;
        });
        default:
          return this.items;
      }
    }
	},
	methods: {
    async loadData(params) {
			this.loading = true;

      // Filter section
      delete params.sort
      if(this.base_role !== 'administrator') {
        params.company_ids = [this.kskId]
      } else if(this.filter.company_ids) {
        params.company_ids = [this.filter.company_ids]
      } else { 
        this.loading = false
        return
      }
      if(this.visibleTab) {
        params.is_public = true ? this.visibleTab === 'public' : false
      }
      if(this.requestTypeTab) {
        params.is_inner = true ? this.requestTypeTab === 'inner' : false
      }
      if(this.filter.period.length) {
        if(typeof this.filter.period === 'string') {
					params.period_execution_first = this.filter.period
					this.filter.period = [this.filter.period]
					delete params.period
        } else {
          params.period_execution_first = this.filter.period[0]
          params.period_execution_second = this.filter.period[1]
				  delete params.period
        }
			}
      if(this.activeSort === 'by-executors' && !this.hideAddress) {
        params.analitics_type = 'houses'
      }
      if(this.activeSort !== 'by-managers') {
        delete params.manager_position
      }
      // End Filter section

      console.log('params end', params)
      return this.$api.ksk
        .load_analitics_requests(params, this.activeSort)
        .then(res => {
          console.log('res', res)
          this.options.itemsLength = res.length;
          switch (this.activeSort) {
            case "by-houses":
              this.items = res.houses;
              this.totals = res.totals
            break;

            case "by-rooms":
              this.items = res.apartments;
              this.totals = res.totals
            break;
            case "by-managers":
              this.items = res.managers;
              this.totals = res.totals
            break;
            case "by-executors":
              this.items = res.employees;
              this.totals = res.totals
            break;
            case "by-categories":
              this.items = res.houses;
              this.totals = res.totals
            break;
            case "all":
            default:
              this.items = res;
          }
          console.log('result', this.items)
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
		},
    hideAddresses(event) {
      console.log('eve', event) 
      if(this.activeSort === 'by-executors' && event) {
        this.headers = [
          { text: "ФИО, телефон", value: "name", width: "200px", sortable: false },
          { text: "Должность", value: "positions", width: "150px", sortable: false },
          { text: "Всего заявок", value: "total_requests_count", width: "120px", sortable: false },
          { text: "Новая", value: "statuses_requests_count.new", width: "70px", sortable: false },
          { text: "Назначено", value: "statuses_requests_count.accepted", width: "70px", sortable: false },
          { text: "В работе", value: "statuses_requests_count.in_work", width: "70px", sortable: false },
          { text: "Просрочено", value: "overdue_requests_count", width: "70px", sortable: false },
          { text: "Исполнено", value: "statuses_requests_count.done", width: "70px", sortable: false },
          { text: "Не исполнено", value: "statuses_requests_count.not_done", width: "70px", sortable: false },
          { text: "Отменено", value: "statuses_requests_count.canceled_by_user", width: "70px", sortable: false },
          { text: "Отклонено", value: "statuses_requests_count.rejected", width: "70px", sortable: false },
          { text: "Ср. оценка", value: "average_rating", width: "70px", sortable: false },
          { text: "% оценки исп заявок", value: "done_ratings_count", width: "70px", sortable: false },
          // { text: "Количество заявок", value: "requests_count", width: "200px", sortable: false },
        ];
        const query = { ...this.$route.query, analitics_type: 'none' };
        this.$router.replace({ query: query });
        this.loadData(query)
      } else {
        this.setHeaders(this.activeSort)
        const query = { ...this.$route.query, analitics_type: 'houses' };
        this.$router.replace({ query: query });
        this.loadData(query)
      }
    },
    getRowClass(groupIndex) {
      return groupIndex % 2 === 0 ? "group-odd" : "group-even";
    },
    getHouseFields(house) {
      if (!house) {
        return;
      }
      return [
        house.apartment_address || '',
        house.total_requests_count || 0,
        house.statuses_requests_count?.new || 0,
        house.statuses_requests_count?.accepted || 0,
        house.statuses_requests_count?.in_work || 0,
        house.overdue_requests_count || 0,
        house.statuses_requests_count?.done || 0,
        house.statuses_requests_count?.not_done || 0,
        house.statuses_requests_count?.canceled_by_user || 0,
        house.statuses_requests_count?.rejected || 0,
        house.average_rating || 0,
        house.done_ratings_count || 0,
      ];
    },
		setFilter(filterType) {
			this.activeSort = filterType;
      this.items = []
      this.visibleTab = ''
      this.requestTypeTab = ''
      for (const key in this.filter) {
        const value = this.filter[key];

        if (Array.isArray(value)) {
          this.filter[key] = "";
        } else if (typeof value === "string") {
          this.filter[key] = "";
        } else {
          this.filter[key] = null;
        }
      }
      this.filter.period = []
      this.setHeaders(this.activeSort)
      let newQuery = {  };
      if(filterType === 'by-managers') {
        this.filter.manager_position = '5f4cda340796c90b114f557e'
        newQuery = { sort: this.activeSort, manager_position: "5f4cda340796c90b114f557e" };
      } else {
        newQuery = { sort: this.activeSort };
      }
      this.$router.replace({ query: newQuery });
			this.loadData(newQuery)
		},
    setHeaders(type) {
      switch (type) {
        case "by-houses":
          this.headers = [
            { text: "№", value: "index", width: "30px", sortable: false },
            { text: "Адрес дома", value: "apartment_address", width: "250px", sortable: false },
            // { text: "Тип помещения", value: "house_number", width: "150px", sortable: false },
            // { text: "Номер дома", value: "house_number", width: "150px", sortable: false },
            { text: "Всего заявок", value: "total_requests_count", width: "100px", sortable: false },
            { text: "Новая", value: "statuses_requests_count.new", width: "100px", sortable: false },
            { text: "Назначено", value: "statuses_requests_count.accepted", width: "100px", sortable: false },
            { text: "В работе", value: "statuses_requests_count.in_work", width: "100px", sortable: false },
            { text: "Просрочено", value: "overdue_requests_count", width: "100px", sortable: false },
            { text: "Исполнено", value: "statuses_requests_count.done", width: "100px", sortable: false },
            { text: "Не исполнено", value: "statuses_requests_count.not_done", width: "100px", sortable: false },
            { text: "Отменено", value: "statuses_requests_count.canceled_by_user", width: "100px", sortable: false },
            { text: "Отклонено", value: "statuses_requests_count.rejected", width: "100px", sortable: false },
            { text: "Ср. оценка", value: "average_rating", width: "100px", sortable: false },
            { text: "% оценки исп заявок", value: "done_ratings_count", width: "100px", sortable: false },
          ];
          break;

        case "by-rooms":
          this.headers = [
            { text: "№", value: "index", width: "30px", sortable: false },
            { text: "Адрес дома", value: "apartment_address", width: "300px", sortable: false },
            { text: "Тип помещения", value: "apartment_type", width: "150px", sortable: false },
            { text: "№ помещения", value: "apartment_number", width: "150px", sortable: false },
            { text: "Всего заявок", value: "total_requests_count", width: "80px", sortable: false },
            { text: "Новая", value: "statuses_requests_count.new", width: "70px", sortable: false },
            { text: "Назначено", value: "statuses_requests_count.accepted", width: "70px", sortable: false },
            { text: "В работе", value: "statuses_requests_count.in_work", width: "70px", sortable: false },
            { text: "Просрочено", value: "overdue_requests_count", width: "70px", sortable: false },
            { text: "Исполнено", value: "statuses_requests_count.done", width: "70px", sortable: false },
            { text: "Не исполнено", value: "statuses_requests_count.not_done", width: "70px", sortable: false },
            { text: "Отменено", value: "statuses_requests_count.canceled_by_user", width: "70px", sortable: false },
            { text: "Отклонено", value: "statuses_requests_count.rejected", width: "70px", sortable: false },
            { text: "Ср. оценка", value: "average_rating", width: "70px", sortable: false },
            { text: "% оценки исп заявок", value: "done_ratings_count", width: "70px", sortable: false },
          ];
          break;

        case "by-managers":
          this.headers = [
            { text: "№", value: "index", width: "30px", sortable: false },
            { text: "ФИО, телефон", value: "name", width: "200px", sortable: false },
            { text: "Адрес дома", value: "apartment_address", width: "300px", sortable: false },
            { text: "Всего заявок", value: "total_requests_count", width: "80px", sortable: false },
            { text: "Новая", value: "statuses_requests_count.new", width: "70px", sortable: false },
            { text: "Назначено", value: "statuses_requests_count.accepted", width: "70px", sortable: false },
            { text: "В работе", value: "statuses_requests_count.in_work", width: "70px", sortable: false },
            { text: "Просрочено", value: "overdue_requests_count", width: "70px", sortable: false },
            { text: "Исполнено", value: "statuses_requests_count.done", width: "70px", sortable: false },
            { text: "Не исполнено", value: "statuses_requests_count.not_done", width: "70px", sortable: false },
            { text: "Отменено", value: "statuses_requests_count.canceled_by_user", width: "70px", sortable: false },
            { text: "Отклонено", value: "statuses_requests_count.rejected", width: "70px", sortable: false },
            { text: "Ср. оценка", value: "average_rating", width: "70px", sortable: false },
            { text: "% оценки исп заявок", value: "done_ratings_count", width: "70px", sortable: false },
          ];
          break;

        case "by-executors":
          this.headers = [
            { text: "№", value: "index", width: "30px", sortable: false },
            { text: "ФИО, телефон", value: "name", width: "200px", sortable: false },
            { text: "Должность", value: "positions", width: "150px", sortable: false },
            { text: "Адрес", value: "apartment_address", width: "200px", sortable: false },
            { text: "Всего заявок", value: "total_requests_count", width: "80px", sortable: false },
            { text: "Новая", value: "statuses_requests_count.new", width: "70px", sortable: false },
            { text: "Назначено", value: "statuses_requests_count.accepted", width: "70px", sortable: false },
            { text: "В работе", value: "statuses_requests_count.in_work", width: "70px", sortable: false },
            { text: "Просрочено", value: "overdue_requests_count", width: "70px", sortable: false },
            { text: "Исполнено", value: "statuses_requests_count.done", width: "70px", sortable: false },
            { text: "Не исполнено", value: "statuses_requests_count.not_done", width: "70px", sortable: false },
            { text: "Отменено", value: "statuses_requests_count.canceled_by_user", width: "70px", sortable: false },
            { text: "Отклонено", value: "statuses_requests_count.rejected", width: "70px", sortable: false },
            { text: "Ср. оценка", value: "average_rating", width: "70px", sortable: false },
            { text: "% оценки исп заявок", value: "done_ratings_count", width: "70px", sortable: false },
            // { text: "Количество заявок", value: "requests_count", width: "200px", sortable: false },
          ];
          break;

          case "by-categories":
            if (this.catalog && Array.isArray(this.catalog.request_categories)) {
              this.headers = [
                { text: "№", value: "index", width: "30px", sortable: false },
                { text: "Адрес дома", value: "apartment_address", width: "200px", sortable: false },
                ...this.catalog.request_categories.map(category => ({
                  text: category.name,
                  value: category.name,
                  width: "100px",
                  sortable: false
                }))
              ];
            } else {
              console.warn("Каталог категорий заявок отсутствует или не является массивом.");
              this.headers = [];
            }
            break;

        case "all":
        default:
          this.headers = [
            { text: "№", value: "index", width: "10px", sortable: false },
            { text: "Номер заявки", value: "pretty_id", width: "120px", sortable: false },
            { text: "Дата создания", value: "created_at", width: "150px", sortable: false },
            { text: "Статус заявки", value: "type", width: "150px", sortable: false },
            { text: "Адрес дома", value: "house", width: "250px", sortable: false },
            { text: "Тип помещения", value: "room_type", width: "150px", sortable: false },
            { text: "№ помещения", value: "apartment_number", width: "120px", sortable: false },
            { text: "Телефон", value: "user_phone", width: "150px", sortable: false },
            { text: "Категория", value: "category_name", width: "150px", sortable: false },
          ];
          break;
      }
    },
		updateFilter(filterName, value) {
      this.filter[filterName] = value;
			this.updateQuery()
    },
    
		async exportExcel() {
			this.isExportingExcel = true;
			const query = this.updateQuery(true);
			query.sort_by = 'created'
			query.sort_type = 'DESC'
			query.export_excel = true
      delete query.sort
			query.company_ids = [this.kskId]
      if(this.visibleTab) {
        query.is_public = true ? this.visibleTab === 'public' : false
      }
      if(this.requestTypeTab) {
        query.is_inner = true ? this.requestTypeTab === 'inner' : false
      }
      if(this.filter.period.length) {
				query.period_execution_first = this.filter.period[0]
				query.period_execution_second = this.filter.period[1]
				delete query.period
			}
      if(this.activeSort === 'by-executors' && !this.hideAddress) {
        query.analitics_type = 'houses'
      }
			try {
				const res = await this.$api.ksk.export_excel_analitics(query, this.activeSort);
				let link = document.createElement("a");
				link.href = res;
				link.download = `Заявки ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
			} catch (error) {
				console.log(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
      for (const key in this.filter) {
        const value = this.filter[key];

        if (Array.isArray(value)) {
          this.filter[key] = "";
        } else if (typeof value === "string") {
          this.filter[key] = "";
        } else {
          this.filter[key] = null;
        }
      }
      this.filter.period = []
      let newQuery = {};
      if(this.activeSort === 'by-managers') {
        this.filter.manager_position = '5f4cda340796c90b114f557e'
        newQuery = { sort: this.activeSort, manager_position: "5f4cda340796c90b114f557e" };
      } else {
        newQuery = { sort: this.activeSort };
      }

      this.visibleTab = '';
			this.requestTypeTab = '';
			this.$router.replace({ query: newQuery });
			this.firstFetch	= false
			await this.loadData(newQuery);
		},

		updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.firstFetch	= false
			this.loadData(query);
		},
    getTotalForColumn(column) {
      switch (column) {
        case "apartment_address":
        case "apartment_type":
        case "apartment_number":
        case "name":
        case "positions":
        case "phone":
          return "";

        case "total_requests_count":
          return this.totals.total_requests_count;

        case "statuses_requests_count.new":
          return this.totals.statuses_requests_count.new;

        case "statuses_requests_count.accepted":
          return this.totals.statuses_requests_count.accepted;

        case "statuses_requests_count.in_work":
          return this.totals.statuses_requests_count.in_work;

        case "statuses_requests_count.rejected":
          return this.totals.statuses_requests_count.rejected;

        case "statuses_requests_count.canceled_by_user":
          return this.totals.statuses_requests_count.canceled_by_user;

        case "statuses_requests_count.done":
          return this.totals.statuses_requests_count.done;

        case "statuses_requests_count.not_done":
          return this.totals.statuses_requests_count.not_done;

        case "overdue_requests_count":
          return this.totals.overdue_requests_count;

        case "average_rating":
          return this.totals.average_rating;

        case "done_ratings_count":
          return this.totals.done_ratings_count;

        default:
          if (this.totals.categories && Array.isArray(this.totals.categories)) {
            const category = this.totals.categories.find(cat => cat.name === column);
            if (category) {
              return category.total_requests_count; // Итого для категории
            }
          }
          return "";
      }
    },
	},
	async created() {
		this.$store.dispatch("catalog/loadKskListConfirm", this.base_role);
    this.$store.dispatch("ksk/load_employee");
	},
	async mounted() {
		try {
			this.$store.dispatch("ksk/load_houses");
      const currentQuery = { ...this.$route.query };
      let newQuery;

      if (!currentQuery.sort) {
        newQuery = { ...currentQuery, sort: this.activeSort };
        this.$router.replace({ query: newQuery });
      } else {
        this.activeSort = currentQuery.sort;
        newQuery = currentQuery; 
      }
      this.setHeaders(this.activeSort)
      await this.loadData(newQuery);

			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style>
.v-data-table.analytics-table tbody tr:hover,
.v-data-table.analytics-table tbody tr.group-odd:hover,
.v-data-table.analytics-table tbody tr.group-even:hover {
  background-color: rgba(214, 243, 224, 0.8) !important;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
.analytics-table.v-data-table th {
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: #B5B9BD;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}
.analytics-table.v-data-table td {
  border-width: 1px 1px 0px 0px !important;
  border-style: solid !important;
  border-color: #B5B9BD !important;
}
.analytics-table.v-data-table tbody tr.totals-row {
  background-color: #d6f3e0 !important;
}
.totals-row {
  position: sticky;
  bottom: 0;
  background: #d6f3e0;
  z-index: 1;
  font-weight: bold;
}
.totals-row td {
  color: #363636;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
}
.analytics-container {
  flex: 1;
  overflow: hidden;
}
.v-data-table.analytics-table tbody tr.group-even {
  background-color: white !important;
}

.v-data-table.analytics-table tbody tr.group-odd {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.hide-address .v-label {
  font-size: 14px;
}
.analytics-table {
  height: 100%;
  overflow-y: auto;
  border: 1px solid #CCCFD1;
  border-radius: 6px;
}
.analytics-table::-webkit-scrollbar {
  width: 8px;
}

.analytics-table::-webkit-scrollbar-track {
  background: transparent;
}

.analytics-table::-webkit-scrollbar-thumb {
  background: #CCCFD1;
  border-radius: 4px;
}

.analytics-table::-webkit-scrollbar-thumb:hover {
  background: #B0B3B5;
}
@-moz-document url-prefix() {
  .analytics-table {
    scrollbar-width: thin;
    scrollbar-color: #CCCFD1 transparent;
  }
}
.w-100 {
	width: 100%;
}
.custom-tabs {
	border: 1px solid #B5B9BD;
	border-radius: 25px;
	cursor: pointer;
	color: #4F5D73;
}
.custom-tabs .tab-active {
	background-color: #D6F3E0 !important;
	border: 1px solid #29A352 !important;
	color: #29A352;
}
.custom-tabs .tab-1 {
	border-right: 1px solid #B5B9BD !important;
}
.custom-tabs-individ {
	border-radius: 25px 0px 0px 25px;
}
.custom-tabs-inner {
	border-radius: 25px 0px 0px 25px;
}
.custom-tabs-public {
	border-radius: 0px 25px 25px 0px;
}
.custom-tabs-tenants {
	border-radius: 0px 25px 25px 0px;
}
.analytics-filter-block {
	background: #E6E6E6;
	padding: 16px 12px 16px 12px;
	border-radius: 18px;

}
.bg-lightgreen {
	background-color: #D6F3E0 !important;
	border: 1px solid #29A352 !important;
}
.analytics-page .btn-sort {
	font-family: Segoe UI;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 21.28px;
	border: 1px solid #B5B9BD;
	color: #6D7379;
	background-color: #fff;
}
.analytics-page {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
}
.analytics-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #000;
}
</style>

<style lang="scss" scoped>

.notifications-table {
	height: 100vh;
}
.desc-bg {
	background-color: rgba(146, 227, 169, 0.2);
	display: flex;
	min-height: 100%;
	align-items: center;
}
.address-column {
	display: flex;
	min-height: 100%;
	align-items: center;
}

.pointer-none {
  pointer-events: none;
}

.disable-item {
  pointer-events: none;
  opacity: 0.5;
}
</style>
